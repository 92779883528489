<template>
  <div class="wrap">
    <Navbar :title="$t('My.PersonalInformation.Page.RealName')"></Navbar>
    <div class="from">
      <div class="title">
        {{ $t('My.PersonalInformation.Page.RealName') }}
      </div>
      <van-field label-align="right" label-width="7.5rem" :border="false" colon v-model="Name"
        :placeholder="$t('My.PersonalInformation.Name.Please')" />
      <div class="button-confirm">
        <van-button size="normal" type="default" block @click="ButtomEvent()">{{
          $t('My.PersonalInformation.Name.Confirm')
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import { getUserInfoNew } from '@/api'
import Navbar from "@/components/JNav";
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'Name',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
    Navbar
  },
  data() {
    return {
      title: "$t{'My.PersonalInformation.Name.title'}",
      Name: '',
      userInfo: {}
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (this.Name != '') {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        })
        // let form = {
        //   cmd: '200006',
        //   nickname: this.Name,
        //   uid: this.module.UIDEvent(),
        // }
        this.$api.editUserInfo({ username: this.Name }, res => {
          getUserInfoNew();
          Toast.clear();
          this.$router.go(-1)
        }, err => {
          Toast.clear();
          this.$toast(err)
        })
        // REQUEST_API(form)
        //   .then((r) => {
        //     console.log(r.data.sc)
        //     if (r.data.sc == 0) {
        //       Toast(this.$t('Hint.success'))
        //       let DataArr = this.module.PersonalEvnet()
        //       DataArr[1].data.player.nickname = this.Name
        //       localStorage.setItem('user-info', JSON.stringify(DataArr))
        //       this.$router.go(-1)
        //     } else {
        //       Toast(this.$t('Hint.failed'))
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e)
        //   })
      } else {
        Toast(this.$t('My.PhoneNew'))
      }
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      this.$api.getUserInfo(res => {
        this.Name = res.username;
        this.userInfo = res;
        Toast.clear();
      }, err => {
        Toast.clear();
      })
    }
  },
  created() {
    this.getUserInfo();
    // this.Name = this.module.PersonalEvnet()[1].data.player.nickname
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;

  .from {
    padding: calc(20rem / 16) calc(15rem / 16) calc(15rem / 16) calc(15rem / 16);

    .title {
      font-size: calc(18rem / 16);
      font-weight: 500;
      color: var(--light);
      margin-bottom: calc(15rem / 16);
    }

  }

  /deep/ .van-cell {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: #132235;
    border: 1px solid transparent;
    outline: 0;
    display: block;
    box-sizing: border-box;
    height: calc(52rem / 16);
    display: flex;
    align-items: center;
    font-size: calc(16rem / 16);
    font-weight: 500;
    line-height: calc(22rem / 16);
    color: var(--light);
    border-radius: inherit;
    padding: calc(6rem / 16) calc(15rem / 16);
    box-sizing: border-box;

    input {
      color: var(--light);
    }

    input::placeholder {
      color: var(--colors);
    }

  }

  .button-confirm {
    width: 100%;
    height: calc(84rem / 16);
    margin-top: calc(50rem / 16);

    .van-button {
      background-image: linear-gradient(179deg, #13a2ba, #087c95);
      border: none;
      height: 54px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      border-radius: 10px;
    }
  }
}
</style>